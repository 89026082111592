@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/perspective-extreme.css';

*,
*::before,
*::after {
  box-sizing: border-box;
  //border: 2px solid lime;
}

@layer utilities {
  .light-gradient {
    background: linear-gradient(to right bottom, rgb(238, 242, 255), rgb(253, 242, 248));
  }

  .dark-gradient {
    background: linear-gradient(180deg, hsla(0, 0%, 0%, 1) 0%, hsla(0, 60%, 3%, 1) 48%, hsl(0, 75%, 14%) 100%);
  }
}

html, body {
  height: 100%;
}

body * {
  @apply transition-colors duration-200;
}

.main-theme {
  @apply light-gradient dark:dark-gradient w-full h-full transition-all;
}

.section-border {
  @apply bg-white dark:bg-zinc-950 w-full border-b border-zinc-200 dark:border-zinc-600;
}

span {
  @apply truncate;
  max-width: 90%;
}

.cdk-overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.cdk-overlay-pane {
  position: absolute !important;
}

.cdk-overlay-connected-position-bounding-box {
  display: flex;
  max-height: 250px;
  max-width: 250px;
}

div.bg-gray-900.bg-opacity-50.fixed.inset-0.z-40 {
  opacity: .5 !important;
}

.custom-box-shadow {
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.custom-tooltip-container {
  &:hover, &:active, &:focus {
    .tooltip {
      margin-left: -26px;
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
}

.tippy-box {
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
}

.custom-popper {
  .tippy-box {
    .tippy-content {
      padding: 0 !important;

      > div {
        height: 0;
      }
    }
  }
}

.social-popper {
  .tippy-box {
    -webkit-backdrop-filter: blur(14.9px);
    backdrop-filter: blur(12px);
    background: rgba(255, 255, 255, 0.24);
    border: 1px solid rgba(255, 255, 255, 0.27);
    border-radius: 0.5rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

    .tippy-content {
      padding: 0 !important;
    }
  }
}

.login-popper {
  margin-top: -10px !important;

  .tippy-box {
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

    .tippy-content {
      padding: 0 !important;
    }

    .tippy-arrow {
      &:before {
        border-bottom-color: transparent !important;
        border-top-color: transparent !important;
      }
    }
  }
}

.pop-animate {
  opacity: 0;
  transform: translateY(20px) scale(0.95);

  &.zoom-in-right-blur {
    animation: zoomInRight 0.5s ease-out forwards;
  }

  &.zoom-in-left-blur {
    animation: zoomInLeft 0.5s ease-out forwards;
  }
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    transform: translateX(-100%) scale(0.85);
  }
  to {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    transform: translateX(100%) scale(0.85);
  }
  to {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

.prompter-tag {
  font-weight: 600 !important;
  //line-height: 1.35rem !important;
  padding: 0 12px 2px 12px;
  transition: background .5s ease-in-out;

  &.green {
    color: rgb(14 159 110);
  }

  &.red {
    color: rgb(224 36 36);
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multiline-ellipsis {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-container {
  @apply bg-gray-50 border border-gray-700 text-gray-900 text-[16px] rounded-lg
  focus:ring-gray-800 focus:border-gray-800 w-full p-2.5
  dark:bg-dark_input-500 dark:focus:ring-gray-400 dark:border-gray-400 dark:placeholder-gray-400
  dark:text-white dark:focus:border-gray-200 flex items-center gap-2 min-h-[64px] md:min-h-[54px];

  transition: transform 0.3s ease;

  .editable-div, .icon-container {
    // Ensure these elements scale properly with the parent
    transition: transform 0.3s ease;;
  }

  .editable-div {
    flex-grow: 1;
    outline: none;


    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.tag-animation {
  display: inline-block;
  opacity: 0;
  vertical-align: top;
}

.custom-filter-container {
  @apply border border-gray-300 focus:outline-none
  focus:ring-4 focus:ring-gray-200 font-medium text-sm
  dark:border-gray-600 dark:hover:border-gray-600
  dark:focus:ring-gray-700 cursor-pointer;
}

.input-filter-container {
  @apply custom-filter-container bg-gray-100 hover:bg-gray-200 dark:bg-zinc-700 dark:hover:bg-gray-600 rounded-md mt-[2px]
}

#narrative:focus {
  box-shadow: 0 0 0 2px transparent; /* Custom focus style: Blue glow */
  outline: none; /* Removes the default outline */
}

/* Theme transition styles */
.theme-transition,
.theme-transition *,
.theme-transition *:before,
.theme-transition *:after {
  transition: all 0.3s ease-in-out !important;
  transition-delay: 0 !important;
}

/* Ensure background color transitions smoothly */
html {
  transition: background-color 0.3s ease-in-out;
}

/* Scrollbar styling */
/* WebKit Browsers (Chrome, Safari, Opera) */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #8c8888;
  border-radius: 4.3px;
  height: 103px;
  opacity: 0.1;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* Firefox */
* {
  scrollbar-color: #8c8888 transparent;
  scrollbar-width: thin;
}

body {
  scrollbar-color: #8c8888 transparent;
  scrollbar-width: thin;
}

/* Modern Edge */
body {
  -ms-overflow-style: none;
}

::-ms-scrollbar {
  width: 4px;
}

::-ms-scrollbar-thumb {
  background-color: #8c8888;
  border-radius: 4.3px;
  height: 103px;
  opacity: 0.1;
}

::-ms-scrollbar-track {
  background: transparent;
}

.top-to-middle {
  animation: moveToMiddleSm 0.5s ease-in-out forwards;

  @keyframes moveToMiddleSm {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(25dvh);
    }
  }

  @media screen and (min-width: 1024px) {
    animation: moveToMiddleLg 0.5s ease-in-out forwards !important;

    @keyframes moveToMiddleLg {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(40dvh);
      }
    }
  }
}

.middle-to-top {
  animation: moveToTopSm 0.5s ease-in-out forwards;

  @keyframes moveToTopSm {
    from {
      transform: translateY(25dvh);
    }
    to {
      transform: translateY(0);
    }
  }

  @media screen and (min-width: 1024px) {
    animation: moveToTopLg 0.5s ease-in-out forwards !important;

    @keyframes moveToTopLg {
      from {
        transform: translateY(40dvh);
      }
      to {
        transform: translateY(0);
      }
    }
  }
}

.more-menu-container {
  @apply h-full w-[250px] sm:w-fit shadow-xl rounded-md overflow-hidden bg-zinc-100 dark:bg-zinc-800;
  border: solid 1px #ffffff7d;
  box-shadow: 0 -2px 10px rgb(255, 255, 255);

  ul {
    @apply font-medium flex flex-col m-0 p-0 rounded-lg;

    li {
      .li-container {
        @apply flex items-center max-w-[250px] h-[40px] cursor-pointer;

        .left-child {
          @apply w-[60px] flex justify-center items-center;
        }

        .right-child {
          @apply w-[calc(100%-60px)];

          span {
            @apply text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0
            hover:text-mypart_red-500 dark:text-white dark:hover:text-mypart_red-500 hover:underline
            md:dark:hover:bg-transparent cursor-pointer text-left w-full;
          }
        }
      }
    }
  }
}

.bottom-boxes {
  @apply flex flex-col-reverse sm:grid justify-center relative -z-10;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;

  &.bottom-boxes-playlist {
    @apply gap-6 translate-y-[calc(43dvh)] lg:translate-y-[60vh];
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }

  &.bottom-boxes-bubble {
    @apply translate-y-[55vh] xl:translate-x-[20px] md:max-w-[1200px] justify-items-center cursor-pointer;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    z-index: 10;

    @media (max-width: 755px) {
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }

    .bubble-wrapper {
      height: 100%;
      min-height: 120px;
      position: relative;
      width: 100%;
    }

    .bubble {
      align-items: center;
      @apply rounded-xl p-6 transition-all duration-300 ease-in-out relative;
      background: rgba(45, 45, 45, 0.95);
      border: white solid 2px;
      -moz-box-shadow: 0 0 20px 0 rgba(247, 247, 247, 0.68);
      -webkit-box-shadow: 0 0 20px 0 rgba(247, 247, 247, 0.68);
      box-shadow: 0 0 20px 0 rgba(247, 247, 247, 0.68);
      display: flex;
      height: 100%;
      justify-content: center;

      min-height: 120px;
      position: relative;
      width: 100%;

      .border-svg {
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;

        .border-animation {
          animation: drawBorder 1.5s ease forwards;
          fill: none;
          stroke: rgba(255, 255, 255, 0.8);
          stroke-dasharray: 400;
          stroke-dashoffset: 400;
          stroke-width: 2;
        }
      }

      &:hover {
        background: rgba(60, 60, 60, 0.95);
        transform: scale(1.05);
      }

      p {
        @apply text-lg font-medium text-center;
        color: rgba(255, 255, 255, 0.95);
        margin: 0;
        padding: 0.875rem;
      }
    }
  }

  p {
    @apply text-base text-white text-center mt-2 leading-[1.2];
  }

  .playlist {
    @apply cursor-pointer w-full max-w-full sm:max-w-[400px] mx-auto;

    img {
      @apply w-full h-auto object-cover rounded-md;
      aspect-ratio: 16 / 9;
    }

    &.bottom-small-screen {
      bottom: calc(env(safe-area-inset-bottom, 0px) + 16px);
    }
  }
}

@keyframes drawBorder {
  to {
    stroke-dashoffset: 0;
  }
}
